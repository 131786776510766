import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location, NgClass } from '@angular/common';

import { NavigationLocation, NextPage, NextPageService } from '@cvx/nextpage';

import { FiltersPanelComponent } from 'src/app/lcms/shared/components/filters-panel/filters-panel.component';
import { ClrService } from 'src/app/lcms/services/clr.service';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [NextPage, FiltersPanelComponent, NgClass],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, AfterViewInit {
  NavigationLocation = NavigationLocation;
  tabLinks!: NodeListOf<HTMLAnchorElement>;

  @Input() set homeMenu(menu: any[]) {
    this.setHomeMenu(menu);
  }

  constructor(
    private npService: NextPageService,
    private router: Router,
    private location: Location,
    public clrService: ClrService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setActiveTabLink();
      }
    });
  }

  private setActiveTabLink(): void {
    this.tabLinks?.forEach((link: any) => {
      const url = link.getAttribute('href');
      // If href of any tab is the current route, add active class else remove
      if (url === this.location.path()) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }

  ngAfterViewInit() {
    this.getAllTabLinks();
  }

  public setHomeMenu(menu: any) {
    this.npService.options.navigationRoutes = menu;
  }

  public getAllTabLinks() {
    const navContainer = document.getElementById('site-specific-nav');
    // Check if the 'site-specific-nav' container from np-menu exists
    if (navContainer) {
      // Use querySelectorAll to select all <a> elements (nav menu tabs) within the container
      this.tabLinks = navContainer.querySelectorAll('a');
    }
  }
}
