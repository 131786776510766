<div class="grid-date-picker-container">
  <mat-form-field
    appearance="fill"
    subscriptSizing="dynamic"
    class="custom-editor-field ag-cell-date-field">
    <input
      #dateGridInput
      [placeholder]="LcmsConstants.DATE_FORMAT"
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="inputDate" />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      panelClass="ag-custom-component-popup ag-cell-date-picker"></mat-datepicker>
  </mat-form-field>
</div>
