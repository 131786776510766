import { Component, Input, OnInit, signal } from '@angular/core';
import { ClrService } from 'src/app/lcms/services/clr.service';

@Component({
  selector: 'app-np-header',
  standalone: true,
  imports: [],
  templateUrl: './np-header.component.html',
  styleUrls: ['./np-header.component.scss'],
})
export class NpHeaderComponent implements OnInit {
  constructor(private clrService: ClrService) {}

  @Input({ required: true }) siteName: string = 'LCMS';
  dropdownIcon = 'icon-chevron-down';
  userName = signal('');

  ngOnInit() {
    this.userName = this.clrService.userName;
  }
}
