import { RefDataFields } from '../ref-data-fields';

export class Product extends RefDataFields {
  public Alias: string;
  public MaterialCode: string;
  public MaterialCodeDescription: string;
  public ProductId: string;
  public ProductName: string;
  public Comments: string;
  public Division: string;
  public PublishToSAP: boolean;

  constructor(data: any) {
    super(data);
    this.MaterialCode = data?.MaterialCode;
    this.MaterialCodeDescription = data?.MaterialCodeDescription;
    this.ProductId = data?.ProductId;
    this.ProductName = data?.ProductName;
    this.Alias = data?.Alias || this.ProductName;
    this.Comments = data?.Comments;
    this.Division = data?.Division;
    this.PublishToSAP = data?.PublishToSAP;
  }
}
