import { AuditFields } from './audit-fields.model';

export class RefDataFields extends AuditFields {
  public IsChecked?: boolean;
  public IsDisplayed?: boolean;
  public IsFilteredOut?: boolean;

  constructor(data?: any) {
    super(data);
    this.IsChecked = false;
    this.IsDisplayed = true;
    this.IsFilteredOut = false;
  }
}
