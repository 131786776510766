import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AsyncPipe, NgFor } from '@angular/common';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';

import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

import { ICustomDropdownEditorParams } from 'src/app/lcms/shared/models/interfaces';

@Component({
  selector: 'app-dropdown-cell-editor',
  standalone: true,
  imports: [
    NgFor,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './dropdown-cell-editor.component.html',
  styleUrls: ['./dropdown-cell-editor.component.scss'],
})
export class DropdownCellEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  public selectedValue: any;
  public selectFilterControl: FormControl = new FormControl('');
  public params!: ICellEditorParams & ICustomDropdownEditorParams;
  public bindLabel = 'Value';
  public bindValue = 'Id';

  /** list of dropdown items */
  protected itemList: any[] = [];

  /** list of banks filtered by search keyword */
  public filteredItemList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelectGridInput', { static: true })
  singleSelectGridInput!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  agInit(params: any): void {
    this.params = params;
    this.selectedValue = this.params.initialSelectedValue
      ? this.params.initialSelectedValue(params)
      : this.params?.value;
    this.bindLabel =
      this.params?.bindLabel != null ? this.params.bindLabel : this.bindLabel;
    this.bindValue =
      this.params?.bindValue != null ? this.params.bindValue : this.bindValue;
    this.itemList = this.params.modelCollection;
    this.filteredItemList.next(this.itemList.slice());
    // listen for search field value changes
    this.selectFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });
  }

  ngAfterViewInit() {
    // this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredItemList are loaded initially
   */
  protected setInitialValue() {
    this.filteredItemList
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelectGridInput.compareWith = (a: any, b: any) =>
          a && b && a[this.bindValue] === b[this.bindValue];
      });
  }

  protected filterItems() {
    if (!this.itemList) {
      return;
    }
    // get the search keyword
    let search = this.selectFilterControl.value;
    if (!search) {
      this.filteredItemList.next(this.itemList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the item
    this.filteredItemList.next(
      this.itemList.filter(
        (item) => item[this.bindLabel].toLowerCase().indexOf(search) > -1
      )
    );
  }

  public onSelectionChange(selection: MatSelectChange): void {
    this.params?.stopEditing(true);
    if (this.params.onSelectionChange) {
      this.params.onSelectionChange(this.params, selection.value);
    }
  }

  public refresh(_params: any): boolean {
    return true;
  }

  public getValue(): any {
    if (
      this.selectedValue != null &&
      this.params.convertSelectedValueToString
    ) {
      return this.selectedValue.toString();
    }
    return this.selectedValue;
  }
}
