import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ICellEditorAngularComp } from 'ag-grid-angular';

import { LcmsService } from '../../services/lcms.service';

@Component({
  selector: 'app-text-cell-editor',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './text-cell-editor.component.html',
  styleUrls: ['./text-cell-editor.component.scss'],
})
export class TextCellEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  private params: any;
  private isMandatory?: boolean;
  
  public valueToBind?: string;
  public maxLength: number = -1;

  @ViewChild('textGridInput', { read: ViewContainerRef })
  public textGridInput: any;

  constructor(private lcmsService: LcmsService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.textGridInput.element.nativeElement.focus();
      this.textGridInput.element.nativeElement.select();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.valueToBind = params.value;
    this.isMandatory = !this.lcmsService.isNullOrUndefined(params.isMandatory)
      ? params.isMandatory
      : false;
    this.maxLength = !this.lcmsService.isNullOrUndefined(params.maxLength)
      ? params.maxLength
      : -1;
  }

  public getValue(): any {
    return this.valueToBind;
  }

  public onKeyup(event: any) {
    if (this.params.onInputChange) {
      this.params.onInputChange(this.params, event.target.value);
    }
  }
}
