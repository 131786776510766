import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private overlayRef!: OverlayRef;
  private spinnerOverlayConfig!: OverlayConfig;

  constructor(private overlay: Overlay) {
    this.spinnerOverlayConfig = {
      hasBackdrop: true,
    };
    this.spinnerOverlayConfig['positionStrategy'] =
      this.positionSpinnerCenter();
    this.overlayRef = this.overlay.create(this.spinnerOverlayConfig);
  }

  positionSpinnerCenter(): PositionStrategy {
    return this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }

  attachSpinnerTemplatePortal(
    templateRef: TemplateRef<any>,
    vcRef: ViewContainerRef
  ) {
    let templatePortal = new TemplatePortal(templateRef, vcRef);
    this.overlayRef.attach(templatePortal);
  }

  public showSpinner(templateRef: TemplateRef<any>, vcRef: ViewContainerRef) {
    if (!this.overlayRef.hasAttached()) {
      this.attachSpinnerTemplatePortal(templateRef, vcRef);
    }
  }

  public hideSpinner() {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
