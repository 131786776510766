<header>
  <div class="lcms-header">
    <div class="lcms-header__logo">
      <a id="hallmark" href="/"></a>
      <div class="app-name">
        <a href="/">{{ siteName }}</a>
      </div>
    </div>
    <div class="lcms-header__settings">
      <div class="user-details-container" #userDetailsSection>
        <!-- <div class="initals-avatar">
            <p class="initals-avatar">{{userAvatar}}</p>
          </div> -->
        <div class="user-details">
          <p class="details-heading">welcome,</p>
          <p class="user-name-text">
            {{ userName() }}
          </p>
        </div>
      </div>
    </div>
  </div>
</header>
