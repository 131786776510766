import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';

import { AccessToRoleMapping } from '../../enums';
import { LcmsService } from '../services/lcms.service';

export const RoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService = inject(CalAngularService);
  const lcmsService = inject(LcmsService);
  const router = inject(Router);
  let roles: any;
  if (authService.cvxClaimsPrincipal && authService.cvxClaimsPrincipal.roles) {
    roles = authService.cvxClaimsPrincipal.roles;
    let requestedRoles = AccessToRoleMapping.find(
      (a: any) => a.Access === route.data['minAccess']
    )?.RequiredRoles;
    if (
      roles &&
      requestedRoles &&
      requestedRoles?.some((role: string) => roles.includes(role))
    ) {
      return true;
    } else {
      router.navigate(['/401']);
      return false;
    }
  } else if (authService.cvxClaimsPrincipal !== undefined) {
    lcmsService.apiRequestsCount++;
    lcmsService.showSpinner$.next(true);
    return lcmsService.checkIfUserIsMember().then((isMember: boolean) => {
      if (isMember) {
        lcmsService.apiRequestsCount--;
        lcmsService.hideSpinner$.next(true);
        localStorage.clear();
        authService.userInitiatedSignIn();
        return false;
      } else {
        router.navigate(['/401']);
        lcmsService.apiRequestsCount--;
        lcmsService.hideSpinner$.next(true);
        return false;
      }
    });
  } else {
    return false;
  }
};
