import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { ClrService } from 'src/app/lcms/services/clr.service';
import { LcmsService } from '../../services/lcms.service';
import { ScreenType } from 'src/app/lcms/enums';

@Component({
  selector: 'app-error-notification',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatTabsModule,
    NgFor,
    NgForOf,
    MatIconModule,
    NgIf,
    NgClass,
    MatChipsModule,
    FormsModule
  ],
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss'],
})
export class ErrorNotificationComponent implements OnInit {
  @Input({ alias: 'errorConfig', required: true })
  errorConfig: any;

  @Input() showFilterCheckbox: boolean = false;

  @Output()
  closePanel = new EventEmitter<boolean>();

  public showErrorRecordsOnly: any = {};

  constructor(private clrService: ClrService,
    private lcmsService: LcmsService){}

  ngOnInit() {
    this.updateFilterCheckbox();
    this.lcmsService.showErrors$.subscribe(e => {
      this.showErrorRecordsOnly = {};
    });
  }

  public close() {
    this.closePanel.emit(true);
    this.clrService.errorPanelClosed$.next(true);
  }

  public getClass() {
    if (
      this.errorConfig.some((ec: any) => {
        return ec.ErrorMessages.length > 0;
      })
    ) {
      return 'show-error';
    } else {
      return 'show-warning';
    }
  }

  public getErrorPanelHeading() {
    let messageString;
    if (
      this.errorConfig.some((ec: any) => {
        return ec.ErrorMessages.length > 0;
      })
    ) {
      messageString = 'Errors';
    } else {
      messageString = 'Warnings';
    }
    return `There are some ${messageString} in data. Please check`;
  }

  public showErrorRecords() {
    this.clrService.showErrorRecordsOnly$.next(this.showErrorRecordsOnly);
  }
  updateFilterCheckbox() {
    if (
      this.errorConfig.some((ec: any) => {
        return ec.ScreenType === ScreenType.FuelSurcharge;
      })
    ) {
      this.showFilterCheckbox = false;
    } else {
      this.showFilterCheckbox = true;
    }
  }
}
