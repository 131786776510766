import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { CustomSnackBarComponent } from '../components/custom-snack-bar/custom-snack-bar.component';
import { SnackBarPanelClassEnum } from '../../enums';
import {
  ICustomSnackBarConfig,
  ISnackBarData,
} from 'src/app/lcms/shared/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private _snackBar: MatSnackBar) {}

  private openSnackBar(
    data: ISnackBarData,
    snackBarConfig: MatSnackBarConfig & ICustomSnackBarConfig
  ) {
    snackBarConfig.data = data;

    snackBarConfig.duration = snackBarConfig.duration ?? 10000;
    snackBarConfig.horizontalPosition =
      snackBarConfig.horizontalPosition ?? 'right';
    snackBarConfig.verticalPosition = snackBarConfig.verticalPosition ?? 'top';
    if (snackBarConfig.indefinite) {
      delete snackBarConfig.duration;
    }
    this._snackBar.openFromComponent(CustomSnackBarComponent, snackBarConfig);
  }

  public info(
    data: ISnackBarData,
    snackBarConfig: MatSnackBarConfig & ICustomSnackBarConfig = {}
  ) {
    data.iconClass = 'fa-info-circle';
    snackBarConfig.panelClass = SnackBarPanelClassEnum.INFO;
    this.openSnackBar(data, snackBarConfig);
  }

  public success(
    data: ISnackBarData,
    snackBarConfig: MatSnackBarConfig & ICustomSnackBarConfig = {}
  ) {
    data.iconClass = 'fa-check';
    snackBarConfig.panelClass = SnackBarPanelClassEnum.SUCCESS;
    this.openSnackBar(data, snackBarConfig);
  }

  public warning(
    data: ISnackBarData,
    snackBarConfig: MatSnackBarConfig & ICustomSnackBarConfig = {}
  ) {
    data.iconClass = 'fa-exclamation-triangle';
    snackBarConfig.panelClass = SnackBarPanelClassEnum.WARNING;
    this.openSnackBar(data, snackBarConfig);
  }

  public error(
    data: ISnackBarData,
    snackBarConfig: MatSnackBarConfig & ICustomSnackBarConfig = {}
  ) {
    data.iconClass = 'fa-times-circle';
    snackBarConfig.panelClass = SnackBarPanelClassEnum.ERROR;
    this.openSnackBar(data, snackBarConfig);
  }

  public open(
    data: ISnackBarData,
    snackBarConfig: MatSnackBarConfig & ICustomSnackBarConfig = {}
  ) {
    snackBarConfig.panelClass = SnackBarPanelClassEnum.INFO;
    this.openSnackBar(data, snackBarConfig);
  }
}
