import { ReferenceDataKeyEnum } from 'src/app/lcms/enums';
import { ReferenceData } from '../reference-data';
import { Carrier } from './carrier.model';
import { Terminal } from './terminal.model';

export class CarrierMapping extends Carrier {
  public Terminals: TerminalMapping[] = [];
  public Mot: string;
  public _etag: string;
  public UI_Updated?: boolean;
  public UI_Invalid?: boolean;

  constructor(data: any, clrReferenceData?: ReferenceData) {
    const carrier: Carrier = clrReferenceData?.getReferenceDataByKeyAndId(
      ReferenceDataKeyEnum.CARRIERS,
      data?.CarrierId
    );
    super({ ...carrier, ...data });
    this.Id = data?.id;
    this.Mot = data?.Mot;
    this.CarrierId = data?.CarrierId;
    this._etag = data?._etag;

    if (data?.Terminals?.length) {
      data.Terminals.forEach((t: any) => {
        const terminalRef = clrReferenceData?.getReferenceDataByKeyAndId(
          ReferenceDataKeyEnum.TERMINALS,
          t?.TerminalNumber
        ) as Terminal;
        this.Terminals.push(new TerminalMapping(t, terminalRef));
      });
    }
  }
}

export class TerminalMapping extends Terminal {
  public IsVisibleOnFullLoad: boolean;
  public IsVisibleOnSplitLoad: boolean;
  public IsVisibleOnMetricPricing: boolean;
  public _etag: string;
  public UI_Updated?: boolean;
  public UI_Invalid?: boolean;

  constructor(data: any, terminalRef?: Terminal) {
    super({ ...terminalRef, ...data });
    this.IsVisibleOnFullLoad = data?.IsVisibleOnFullLoad;
    this.IsVisibleOnSplitLoad = data?.IsVisibleOnSplitLoad;
    this.IsVisibleOnMetricPricing = data?.IsVisibleOnMetricPricing;
    this.TerminalNumber = data?.TerminalNumber;
    this._etag = data?._etag;
  }
}

export class DeleteCarrierTerminalMapping {
  public CarrierId: string;
  public TerminalNumber: string;
  public EntityType: string;
  public Mot: string;
  public Id: string;
  public _etag: string;

  constructor(data: any) {
    this.CarrierId = data?.CarrierId;
    this.TerminalNumber = data?.TerminalNumber;
    this.EntityType = data?.EntityType;
    this.Mot = data?.Mot;
    this.Id = data?.Id;
    this._etag = data?._etag;
  }
}
