import { Injectable } from '@angular/core';

import { Subject, firstValueFrom } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';
import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';

import { LcmsConstants, ValueConstants } from '../../constants';
import {
  NonProdADGroupsForAccess,
  ProdADGroupsForAccess,
  AccessToRoleMapping,
  AgGridColumnFilterTypeEnum,
  AgGridColumnTypeEnum,
  FileTypeEnum,
  MessagesEnum,
} from '../../enums';
import { DatePickerCellEditorComponent } from '../ag-grid/date-picker-cell-editor/date-picker-cell-editor.component';
import { NumericCellEditorComponent } from '../ag-grid/numeric-cell-editor/numeric-cell-editor.component';
import { DropdownCellEditorComponent } from '../ag-grid/dropdown-cell-editor/dropdown-cell-editor.component';
import { CheckboxFloatingFilterComponent } from '../ag-grid/checkbox-floating-filter/checkbox-floating-filter.component';
import { TextCellEditorComponent } from '../ag-grid/text-cell-editor/text-cell-editor.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LcmsService {
  public agGridColumnTypes = {
    [AgGridColumnTypeEnum.NUMBER_COLUMN]: {
      cellEditor: NumericCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.NUMBER_COLUMN_FILTER,
      filterParams: {
        defaultOption: 'equals',
        buttons: ['clear'],
      },
    },
    [AgGridColumnTypeEnum.FORMATTED_NUMBER_COLUMN_0]: {
      cellEditor: NumericCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.NUMBER_COLUMN_FILTER,
      valueFormatter: (params: any) => this.numericCommaFormatter(params),
      getQuickFilterText: (params: any) => this.formatText(params.value),
      filterParams: {
        defaultOption: 'equals',
        buttons: ['clear'],
        textFormatter: (val: any) => this.formatText(val),
      },
    },
    [AgGridColumnTypeEnum.FORMATTED_NUMBER_COLUMN_2]: {
      cellEditor: NumericCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.NUMBER_COLUMN_FILTER,
      valueFormatter: (params: any) => this.numericCommaFormatter(params, 2),
      getQuickFilterText: (params: any) => this.formatText(params.value, 2),
      filterParams: {
        defaultOption: 'equals',
        buttons: ['clear'],
        textFormatter: (val: any) => this.formatText(val, 2),
      },
    },
    [AgGridColumnTypeEnum.FORMATTED_NUMBER_COLUMN_4]: {
      cellEditor: NumericCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.NUMBER_COLUMN_FILTER,
      valueFormatter: (params: any) => this.numericCommaFormatter(params, 4),
      getQuickFilterText: (params: any) => this.formatText(params.value, 4),
      filterParams: {
        defaultOption: 'equals',
        buttons: ['clear'],
        textFormatter: (val: any) => this.formatText(val, 4),
      },
    },
    [AgGridColumnTypeEnum.DOLLAR_CURRENCY_COLUMN]: {
      cellEditor: NumericCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.NUMBER_COLUMN_FILTER,
      valueFormatter: (params: any) => this.priceValueFormatter(params, 2),
      getQuickFilterText: (params: any) => this.formatText(params.value, 2),
      filterParams: {
        defaultOption: 'equals',
        buttons: ['clear'],
        textFormatter: (val: any) => this.formatText(val, 2),
      },
    },
    [AgGridColumnTypeEnum.DOLLAR_CURRENCY_COLUMN_3]: {
      cellEditor: NumericCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.NUMBER_COLUMN_FILTER,
      valueFormatter: (params: any) => this.priceValueFormatter(params, 3),
      getQuickFilterText: (params: any) => this.formatText(params.value, 3),
      filterParams: {
        defaultOption: 'equals',
        buttons: ['clear'],
        textFormatter: (val: any) => this.formatText(val, 2),
      },
    },
    [AgGridColumnTypeEnum.TEXT_COLUMN]: {
      cellEditor: TextCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.TEXT_COLUMN_FILTER,
      filterParams: {
        buttons: ['clear'],
      },
    },
    [AgGridColumnTypeEnum.TEXTAREA_COLUMN]: {
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      filter: AgGridColumnFilterTypeEnum.TEXT_COLUMN_FILTER,
      filterParams: {
        buttons: ['clear'],
      },
    },
    [AgGridColumnTypeEnum.DATE_COLUMN]: {
      cellEditor: DatePickerCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.DATE_COLUMN_FILTER,
      getQuickFilterText: this.dateFormatter,
      valueFormatter: this.dateFormatter,
      filterParams: {
        buttons: ['clear'],
        comparator: this.dateComparator,
        browserDatePicker: true,
      },
    },
    [AgGridColumnTypeEnum.DATE_TIME_COLUMN]: {
      cellEditor: DatePickerCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.DATE_COLUMN_FILTER,
      getQuickFilterText: this.dateFormatter,
      valueFormatter: this.dateFormatterWithTime,
      filterParams: {
        buttons: ['clear'],
        comparator: this.dateComparator,
        browserDatePicker: true,
      },
    },
    [AgGridColumnTypeEnum.LIST_COLUMN]: {
      cellEditor: DropdownCellEditorComponent,
      filter: AgGridColumnFilterTypeEnum.SET_COLUMN_FILTER,
    },
    [AgGridColumnTypeEnum.CHECKBOX_COLUMN]: {
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      filter: AgGridColumnFilterTypeEnum.TEXT_COLUMN_FILTER,
      floatingFilterComponent: CheckboxFloatingFilterComponent,
      filterParams: {
        buttons: ['clear'],
      },
      suppressFloatingFilterButton: true,
    },
  };

  constructor(private authService: CalAngularService) {}

  public showErrors$: Subject<any> = new Subject<any>();
  public hideErrors$: Subject<any> = new Subject<any>();

  public showSpinner$: Subject<any> = new Subject<any>();
  public hideSpinner$: Subject<any> = new Subject<any>();
  public apiRequestsCount = 0;

  public isCellValueChanged(oldValue: any, newValue: any) {
    if (newValue) {
      return oldValue ? newValue != oldValue : true;
    } else {
      return oldValue ? true : false;
    }
  }

  public isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  private static isnullorundefined(value: any) {
    return value === null || value === undefined;
  }

  public priceValueFormatter(params: any, precision: number = 2) {
    if (Number.isNaN(params.value)) {
      return '';
    } else if (!LcmsService.isnullorundefined(params.value)) {
      const value = parseFloat(params.value);
      return `${LcmsConstants.DEFAULT_CURRENCY}${value.toLocaleString(
        undefined,
        {
          maximumFractionDigits: precision,
          minimumFractionDigits: precision,
        }
      )}`;
    } else {
      return params.value;
    }
  }

  public formatText(value: any, precision: number = 0) {
    if (LcmsService.isnullorundefined(value)) {
      return '';
    } else {
      return value
        .toLocaleString(undefined, {
          maximumFractionDigits: precision,
          minimumFractionDigits: precision,
        })
        .replaceAll(',', '');
    }
  }

  public numericCommaFormatter(params: any, precision: number = 0) {
    if (Number.isNaN(params.value)) {
      return '';
    } else if (
      !LcmsService.isnullorundefined(params.value) &&
      params.value !== 0
    ) {
      const value = parseFloat(params.value);
      if (precision === 0 && params.value < 0.5 && params.value > -0.5) {
        precision = 2;
      }
      return value.toLocaleString(undefined, {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      });
    } else {
      return params.value;
    }
  }

  public dateFormatter(params: any) {
    return LcmsService.DATE_FORMATTER(params);
  }

  public static DATE_FORMATTER(params: any) {
    if (params.value) {
      const date = new Date(params.value);
      let day = LcmsService.padZero(date.getDate());
      let monthIndex = date.getMonth();
      let monthName = ValueConstants.MONTH_SHORT_ABBV[monthIndex];
      let year = date.getFullYear();

      return `${day}-${monthName}-${year}`;
    } else return params.value;
  }

  public dateFormatterWithTime(params: any) {
    if (params.value) {
      const date = new Date(params.value);
      let formattedDate = LcmsService.DATE_FORMATTER(params);

      return `${formattedDate} ${LcmsService.padZero(
        date.getHours()
      )}:${LcmsService.padZero(date.getMinutes())}:${LcmsService.padZero(
        date.getSeconds()
      )}`;
    } else return params.value;
  }

  public dateComparator(filterLocalDate: any, cellValue: any) {
    let dateAsString = cellValue;
    if (dateAsString == null) return 0;

    let dateParts = dateAsString.split('-');
    let month = Number(dateParts[1]) - 1;
    let day = Number(dateParts[2].substring(0, 2));
    let year = Number(dateParts[0]);
    let cellDate = new Date(year, month, day);

    if (typeof filterLocalDate === 'string') {
      let dateAsString = filterLocalDate;

      let dateParts = dateAsString.split('-');
      let month = Number(dateParts[1]) - 1;
      let day = Number(dateParts[2].substring(0, 2));
      let year = Number(dateParts[0]);
      filterLocalDate = new Date(year, month, day);
    }

    if (cellDate < filterLocalDate) {
      return 1;
    } else if (cellDate > filterLocalDate) {
      return -1;
    } else {
      return 0;
    }
  }

  public isNumberColumn(params: any) {
    return [
      AgGridColumnTypeEnum.NUMBER_COLUMN,
      AgGridColumnTypeEnum.FORMATTED_NUMBER_COLUMN_0,
      AgGridColumnTypeEnum.FORMATTED_NUMBER_COLUMN_2,
      AgGridColumnTypeEnum.FORMATTED_NUMBER_COLUMN_4,
    ].includes(params.colDef?.type);
  }

  public isNumberColumnWithNegativeValue(params: any) {
    const value = params?.value;
    return value && this.isNumberColumn(params) && !isNaN(value) && value < 0;
  }

  public readFile(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (e?.target?.result) {
          resolve(e.target.result as ArrayBuffer);
        } else {
          reject(new Error(MessagesEnum.UNABLE_TO_READ_FILE));
        }
      };
      reader.onerror = (e: any) => {
        reject(new Error(MessagesEnum.UNABLE_TO_READ_FILE));
      };
      reader.readAsArrayBuffer(file);
    });
  }
  public getTimeZoneFromDate(date: Date): any {
    if (date && !isNaN(date as any)) {
      const temp = date.toString().match(/\(([^)]+)\)$/);
      if (temp) {
        return temp[1];
      }
    }
    return null;
  }

  public getTimeZoneAbbvFromDate(date: Date): any {
    const timeZoneString = this.getTimeZoneFromDate(date);

    if (timeZoneString) {
      return `(${timeZoneString
        .split(' ')
        .map((word: string) => word[0])
        .join('')})`;
    }

    return null;
  }

  public getDateISOWithoutTime(date: any) {
    if (typeof date === 'object') {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    } else {
      return date;
    }
  }

  public saveFileBuffer(
    buffer: ArrayBuffer,
    type: string,
    fileName: string,
    showFilePicker: boolean = true
  ): void {
    let description = '';
    let fileType = '';
    let fileFormat: string[] = [];
    switch (type) {
      case FileTypeEnum.EXCEL: {
        description = 'Excel file';
        fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileFormat = ['.xlsx'];
        break;
      }
      case FileTypeEnum.TEXT: {
        description = 'Text file';
        fileType = 'text/plain';
        fileFormat = ['.txt', '.text'];
        break;
      }
      case FileTypeEnum.ZIP: {
        description = 'Zip file';
        fileType = 'application/zip';
        fileFormat = ['.zip'];
        break;
      }
    }
    const blob = new Blob([buffer], {
      type: fileType,
    });
    if (showFilePicker) {
      this.showDownloadFilePicker(blob, fileName, [
        {
          description,
          accept: {
            [fileType]: fileFormat,
          },
        },
      ]);
    } else {
      this.downloadFileInBrowser(blob, fileName);
    }
  }

  private async showDownloadFilePicker(
    blob: any,
    fileName: string,
    types: any[] = []
  ) {
    // Prompt the user to select a location and filename for saving the file
    try {
      // If the File System Access API is supported…
      if ('showSaveFilePicker' in window) {
        try {
          // Show the file save dialog.
          const handle = await (window as any).showSaveFilePicker({
            types,
            suggestedName: fileName,
          });
          // Write the blob to the file.
          const writable = await handle.createWritable();
          await writable.write(blob);
          await writable.close();
          return;
        } catch (err: any) {
          // Fail silently if the user has simply canceled the dialog.
          if (err.name !== 'AbortError') {
            console.error(err.name, err.message);
            this.downloadFileInBrowser(blob, fileName);
            return;
          }
        }
      }
    } catch (err) {
      console.error('Error saving file:', err);
    }
  }

  private downloadFileInBrowser(blob: any, fileName: string) {
    const url = window.URL.createObjectURL(blob); // Convert Blob to URL

    // Create a hidden anchor element
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    // Set anchor's href and download attributes
    a.href = url;
    a.download = fileName;

    // Click the anchor to trigger the download
    a.click();

    // Remove the anchor from the DOM
    document.body.removeChild(a);

    // Release the Blob URL
    window.URL.revokeObjectURL(url);
  }

  public static padZero(num: number) {
    return num.toString().padStart(2, '0');
  }

  public convertDateToStringFormat(date: Date, format: string): string {
    let result = '';

    const day = LcmsService.padZero(date.getDate());
    const month = LcmsService.padZero(date.getMonth() + 1);
    const year = date.getFullYear().toString();
    const hours = LcmsService.padZero(date.getHours());
    const minutes = LcmsService.padZero(date.getMinutes());
    const seconds = LcmsService.padZero(date.getSeconds());

    result = format
      .replace('DD', day)
      .replace('MM', month)
      .replace('YYYY', year)
      .replace('HH', hours)
      .replace('mm', minutes)
      .replace('SS', seconds);

    return result;
  }

  public userHasReadWriteAccess(requestedAccess: string | undefined): boolean {
    if (requestedAccess) {
      const requestedRoles = AccessToRoleMapping.find(
        (a: any) => a.Access === requestedAccess
      )?.RequiredRoles;
      const roles = this.authService.cvxClaimsPrincipal.roles;
      if (
        roles &&
        requestedRoles &&
        requestedRoles.some((role: string) => roles.includes(role))
      ) {
        return true;
      }
    }
    return false;
  }

  public handleKeyDown(
    event: KeyboardEvent,
    callback: Function,
    thisRef: any,
    params: any[]
  ): void {
    if (event.key === 'Enter' || event.key === ' ') {
      callback.apply(thisRef, params);
    }
  }

  public getColorFormString(str: string) {
    const hash = this.hashString(str);
    return this.intToRGB(hash);
  }

  private hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    return hash;
  }

  private intToRGB(hash: number): string {
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;

    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  }

  async checkIfUserIsMember() {
    let groups: any;
    if (environment.production) {
      groups = ProdADGroupsForAccess;
    } else {
      groups = NonProdADGroupsForAccess;
    }
    let isMember = await firstValueFrom(this.authService.isInGroup(groups));
    return isMember;
  }

  public getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    return [
      ...this.getCustomContextMenuItems(params),
      'separator',
      ...this.getDefaultContextMenuItems(params),
    ];
  }

  private getCustomContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    return [
      {
        name: 'Clear Grid Filters',
        action: () => {
          params.api.setFilterModel(null);
        },
        disabled: Object.keys(params.api.getFilterModel())?.length === 0,
        icon: `<em style="width='15' height='10'" class="fas fa-filter"></em>`,
      },
    ];
  }

  public getDefaultContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    return ['cut', 'copy', 'copyWithHeaders', 'paste'];
  }

  public startOfWeek(date: Date) {
    // Calculate the difference between the date's day of the month and its day of the week
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    // Set the date to the start of the week by setting it to the calculated difference
    return new Date(date.setDate(diff));
  }
  public fscRestrictedSaveDate() {
    const mondayThisWeek = this.startOfWeek(new Date()) // new Date(today);
    const previousWeekDate = new Date(mondayThisWeek);
    previousWeekDate.setDate(mondayThisWeek.getDate() - 28); // ToDo:- Reduce to 7 days for disabling edit after testing done
    previousWeekDate.setHours(0, 0, 0, 0);
    return new Date(previousWeekDate);
  }
}
