import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MatNativeDateModule } from '@angular/material/core';
import { CalAngularModule } from '@cvx/cal-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AppInterceptor } from './app.interceptor';
import { LcmsHomeComponent } from './lcms/lcms-home/lcms-home.component';
import { NpHeaderComponent } from './root/components/np-header/np-header.component';
import { AppInsightsService } from './root/services/app-insights.service';
import { CustomErrorHandlerService } from './root/services/custom-error-handler.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    CalAngularModule.forRoot({
      autoSignIn: false,
      popupForLogin: false,
      cacheLocation: 'localStorage',
      instance: 'https://login.microsoftonline.com/',
      tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
      clientId: environment.clientId,
      // redirectUri will need to match a redirectUri setup in your app registration
      redirectUri: environment.calRedirectURI,
      oidcScopes: ['openid', 'profile', 'User.Read', 'offline_access'],
      graphScopes: ['User.Read', 'User.Read.All'],
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LcmsHomeComponent,
    NpHeaderComponent,
    MatProgressSpinnerModule,
    OverlayModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    MatNativeDateModule,
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
