<app-np-header [siteName]="siteName" *ngIf="isLoggedIn"></app-np-header>
<app-lcms-home *ngIf="isLoggedIn"></app-lcms-home>

<ng-template #progressSpinnerRef>
  <mat-progress-spinner
    class="spinner-color"
    diameter="100"
    mode="indeterminate">
  </mat-progress-spinner>
</ng-template>
