import { Injectable } from '@angular/core';

import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements IErrorService {
  constructor(private appInsightsService: AppInsightsService) {}

  handleError(error: any): void {
    console.error(error);
    this.appInsightsService.logException(error, 3, {
      uri: window.location.href,
    });
  }
}
