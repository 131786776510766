export class AuditFields {
  public sys_createdBy: string;
  public sys_createdOn: string;
  public sys_isDeleted: boolean;
  public sys_modifiedBy: string;
  public sys_modifiedOn: string;

  constructor(data: any) {
    this.sys_createdBy = data?.sys_createdBy;
    this.sys_createdOn = data?.sys_createdOn;
    this.sys_isDeleted = data?.sys_isDeleted;
    this.sys_modifiedBy = data?.sys_modifiedBy;
    this.sys_modifiedOn = data?.sys_modifiedOn;
  }
}
