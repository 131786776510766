<app-nav-bar [homeMenu]="homeMenu"></app-nav-bar>
<div style="height: calc(100vh - 133px); overflow: auto;">
  <app-error-notification
  class="content"
  style="display: block"
  [ngClass]="{ filterPanelOpened: clrService.filterExpanded() }"
  (closePanel)="closePanel()"
  [errorConfig]="errorConfig"
  [showFilterCheckbox]="true"
  *ngIf="showErrorPanel"></app-error-notification>
<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
</div>