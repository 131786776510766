import { RefDataFields } from '../ref-data-fields';

export class Terminal extends RefDataFields {
  public Id: string;
  public Alias: string;
  public Comments: string;
  public MarketRegion: any;
  public MOT: string;
  public RetailRegion: any;
  public SAPPlantNumber: string;
  public Terminal: string;
  public TerminalAddress: string;
  public TerminalCity: string;
  public TerminalNumber: string;
  public TerminalState: string;
  public TerminalZip: string;

  constructor(data: any) {
    super(data);
    this.Id = data?.id;
    this.Comments = data?.Comments;
    this.MarketRegion = data?.MarketRegion;
    this.MOT = data?.Mot;
    this.RetailRegion = data?.RetailRegion;
    this.SAPPlantNumber = data?.SapPlantNumber;
    this.Terminal = data?.Terminal;
    this.Alias = data?.Alias || this.Terminal;
    this.TerminalAddress = data?.TerminalAddress;
    this.TerminalCity = data?.TerminalCity;
    this.TerminalNumber = data?.TerminalNumber;
    this.TerminalState = data?.TerminalState;
    this.TerminalZip = data?.TerminalZip;
  }
}
