<div class="d-flex align-items-center">
  <em
    class="fa"
    *ngIf="data?.iconClass"
    [ngClass]="data.iconClass"
    style="font-size: 130%"></em>
  <div matSnackBarLabel style="flex-grow: 1; padding: 0 40px 0 15px">
    <div class="d-flex-column">
      <div
        class="align-self-center"
        style="font-weight: bold"
        *ngIf="data?.headerText">
        {{ data.headerText }}
      </div>
      <div class="align-self-center">{{ data.message }}</div>
    </div>
  </div>
  <div
    matSnackBarActions
    class="d-flex justify-content-center align-items-center">
    <em
      class="fa fa-times snack-bar-close-btn"
      matSnackBarAction
      (click)="close()"
      style="cursor: pointer; font-size: 130%"></em>
  </div>
</div>
