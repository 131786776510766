import { BrowserModule } from '@angular/platform-browser';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgClass } from '@angular/common';

import { Subject, takeUntil } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';

import { ClrHomeComponent } from 'src/app/lcms/truck/clr/clr-home/clr-home.component';
import { NavBarComponent } from 'src/app/root/components/nav-bar/nav-bar.component';
import { ClrService } from '../../services/clr.service';
import {
  AccessToRoleMapping,
  LCMSAccessTypes,
  MessagesEnum,
} from '../../enums';
import { ErrorNotificationComponent } from '../../shared/components/error-notification/error-notification.component';
import { LcmsService } from '../../shared/services/lcms.service';
import { AppInsightsService } from 'src/app/root/services/app-insights.service';

@Component({
  selector: 'app-truck-home',
  standalone: true,
  imports: [
    NgClass,
    BrowserModule,
    RouterModule,
    ClrHomeComponent,
    NavBarComponent,
    ErrorNotificationComponent,
  ],
  templateUrl: './truck-home.component.html',
  styleUrls: ['./truck-home.component.scss'],
})
export class TruckHomeComponent implements OnDestroy, OnInit {
  public homeMenu = [
    {
      title: 'carrier lane rates',
      path: 'truck/carrier-lane-rates',
      minAccess: LCMSAccessTypes.CLR_RO,
    },
    {
      title: 'rate master',
      path: 'truck/rate-master',
      minAccess: LCMSAccessTypes.RATEMASTER_RO,
    },
    {
      title: 'reference data',
      path: 'truck/reference-data',
      minAccess: LCMSAccessTypes.REFDATA_RO,
    },
    // {
    //   title: 'reports',
    //   path: 'truck/reports',
    // },
  ];
  public showErrorPanel = false;
  public errorConfig: any;
  private loadedComponent: any;
  private truckHomeDestroyed$ = new Subject<boolean>();

  constructor(
    private authService: CalAngularService,
    private appInsightsService: AppInsightsService,
    public clrService: ClrService,
    public lcmsService: LcmsService
  ) {
    let roles = this.authService.cvxClaimsPrincipal.roles;
    this.homeMenu = this.homeMenu.filter((menu: any) => {
      if (!menu.minAccess) {
        return true;
      }
      let requestedRoles = AccessToRoleMapping.find(
        (a: any) => a.Access === menu.minAccess
      )?.RequiredRoles;
      if (
        roles &&
        requestedRoles &&
        requestedRoles.some((role: string) => roles.includes(role))
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  ngOnInit() {
    this.appInsightsService.logPageView(TruckHomeComponent.name);
    this.lcmsService.showErrors$
      .pipe(takeUntil(this.truckHomeDestroyed$))
      .subscribe((errors: any) => {
        this.errorConfig = errors;
        this.showErrorPanel = true;
      });

    this.lcmsService.hideErrors$
      .pipe(takeUntil(this.truckHomeDestroyed$))
      .subscribe((data) => {
        this.closePanel();
      });
  }

  public onRouterOutletActivate(component: any) {
    this.loadedComponent = component;
    this.appInsightsService.logPageView(this.loadedComponent.constructor.name);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (
      this.loadedComponent &&
      this.loadedComponent.hasUnsavedChanges &&
      this.loadedComponent.hasUnsavedChanges()
    ) {
      $event.returnValue = MessagesEnum.UNSAVED_CHANGES_WARNING;
    }
  }

  public closePanel() {
    this.showErrorPanel = false;
  }

  ngOnDestroy(): void {
    this.truckHomeDestroyed$.next(true);
  }
}
