<div class="error-container mb-3">
  <mat-accordion [togglePosition]="'before'" class="error-accordion">
    <mat-expansion-panel>
      <mat-expansion-panel-header [ngClass]="getClass()">
        <mat-panel-title>
          <div>
            <span class="error-description">
              {{ getErrorPanelHeading() }}
            </span>
            <span
              (click)="close(); $event.stopPropagation()"
              class="error-closeIcon icon-remove"></span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <ng-container *ngFor="let error of errorConfig">
          <mat-tab [label]="error.ScreenType" labelClass="clr-tab-label">
            <div class="messages-container row">
              <div
                *ngIf="error.ErrorMessages.length > 0"
                class="col errors-container">
                <div class="error-header">
                  <mat-chip>
                    <div class="error-type-header">
                      {{ error.ErrorMessages.length }} Error(s)
                    </div>
                  </mat-chip>
                  <form class="branded d-flex" *ngIf="showFilterCheckbox">
                    <p class="error-checkbox-container">
                      <input
                        type="checkbox"
                        name="show-error-records-checkbox"
                        id="show-error-records-checkbox"
                        [(ngModel)]="showErrorRecordsOnly[error.ScreenType]"
                        (change)="showErrorRecords()"/>
                      <label for="show-error-records-checkbox">filter records</label>
                    </p>
                  </form>
                </div>
                <div
                  *ngFor="let message of error.ErrorMessages"
                  class="error-message">
                  <span>{{ message }}</span>
                </div>
              </div>
              <div
                *ngIf="error.WarningMessages.length > 0"
                class="col warnings-container">
                <mat-chip>
                  <div class="warning-type-header">
                    {{ error.WarningMessages.length }} Warning(s)
                  </div>
                </mat-chip>

                <div
                  *ngFor="let message of error.WarningMessages"
                  class="warning-message">
                  <span>{{ message }}</span>
                </div>
              </div>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-expansion-panel>
  </mat-accordion>
</div>
