import { ReferenceDataKeyEnum } from 'src/app/lcms/enums';
import { ClrService } from 'src/app/lcms/services/clr.service';
import { AuditFields } from '../audit-fields.model';
import { Product } from './product.model';

export class TerminalProductMapping extends AuditFields {
  public ProductIds?: string[] = [];
  public Products: ProductMapping[] = [];
  public Alias!: string;
  public TerminalNumber: string;
  public EntityType: string = ReferenceDataKeyEnum.TERMINALS;

  constructor(data: any, clrService: ClrService) {
    super(data);
    this.ProductIds = data?.ProductIds || [];
    this.TerminalNumber = data?.TerminalNumber;

    const terminal = clrService.clrReferenceData.getReferenceDataByKeyAndId(
      ReferenceDataKeyEnum.TERMINALS,
      data?.TerminalNumber
    );

    this.Alias = terminal?.Alias;

    if (this.ProductIds?.length) {
      this.ProductIds.forEach((productId: any) => {
        this.Products.push(new ProductMapping(productId, clrService));
      });
    }
  }
}

export class ProductMapping {
  public ProductId: string;
  public ProductName!: string;
  public Alias!: string;

  constructor(productId: string, clrService: ClrService) {
    this.ProductId = productId;

    const product = clrService.clrReferenceData.getReferenceDataByKeyAndId(
      ReferenceDataKeyEnum.PRODUCT,
      productId
    ) as Product;
    this.ProductName = product?.ProductName;
    this.Alias = product?.Alias;
  }
}
