import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { Location } from '@angular/common';
import {
  finalize,
  firstValueFrom,
  from,
  lastValueFrom,
  Observable,
} from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';

import { environment } from 'src/environments/environment';
import { MicroServiceType } from './lcms/enums';
import { LcmsService } from './lcms/shared/services/lcms.service';

export const MICRO_SERVICE_TYPE = new HttpContextToken<string>(
  () => MicroServiceType.LCMS_BFF
);

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private authService: CalAngularService,
    private location: Location,
    private lcmsService: LcmsService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.lcmsService.apiRequestsCount++;
    this.lcmsService.showSpinner$.next(true);

    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const targetMicroService = request.context.get(MICRO_SERVICE_TYPE);

    let tokenURL = '';
    if (targetMicroService === MicroServiceType.LCMS_BFF) {
      tokenURL = environment.LCMS_BFF_tokenEndpoint;
    }
    const tok = await firstValueFrom(
      this.authService.getAADToken([tokenURL], false)
    );
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${tok}`),
    });

    return await lastValueFrom(
      next.handle(request).pipe(
        finalize(() => {
          this.lcmsService.apiRequestsCount--;
          if (this.lcmsService.apiRequestsCount === 0) {
            this.lcmsService.hideSpinner$.next(true);
          }
        })
      )
    );
  }
}
