import { Carrier } from './truck/carrier.model';
import { ReferenceDataKeyEnum } from 'src/app/lcms/enums';
import { Terminal } from './truck/terminal.model';
import { Product } from './truck/product.model';
import { ReferenceDataItem } from './reference-data-item.model';

export class ReferenceData {
  public AllRefData: RefDataWrapper[] = [];
  private Entities: any = {};

  constructor(data: any) {
    for (let item of data) {
      this.AllRefData.push(new RefDataWrapper(item));
    }
    this.generateRefData();
  }

  private generateRefData() {
    this.AllRefData.forEach((refData: RefDataWrapper) => {
      this.Entities[refData.EntityType] = refData.Items;
    });
  }

  public getReferenceDataByKey(key: string): any[] {
    return this.Entities[key] || [];
  }

  public getReferenceDataByKeyAndId(key: string, id: any): any {
    const refData = this.getReferenceDataByKey(key);
    switch (key) {
      case ReferenceDataKeyEnum.CARRIERS: {
        return refData.find((c: any) => c.CarrierId === id);
      }
      case ReferenceDataKeyEnum.TERMINALS: {
        return refData.find((t: any) => t.TerminalNumber === id);
      }
      case ReferenceDataKeyEnum.PRODUCT: {
        return refData.find((t: any) => t.ProductId === id);
      }
      default: {
        return refData.find((c: ReferenceDataItem) => c.Id === id);
      }
    }
  }

  public getReferenceDataByKeyAndName(key: string, name: string): any {
    const refData = this.getReferenceDataByKey(key);
    return refData.find((c: any) => c.Name === name);
  }
}

class RefDataWrapper {
  public EntityType: string;
  public Items: any[] = [];

  constructor(data: any) {
    this.EntityType = data?.EntityType;
    for (let item of data?.Items ?? []) {
      this.Items.push(this.createRefDataItem(this.EntityType, item));
    }
    if (
      this.EntityType === ReferenceDataKeyEnum.CARRIERS ||
      this.EntityType === ReferenceDataKeyEnum.TERMINALS ||
      this.EntityType === ReferenceDataKeyEnum.PRODUCT
    ) {
      this.Items.sort(this.compareForSort);
    }
  }

  private createRefDataItem(entityType: string, data: any) {
    switch (entityType) {
      case ReferenceDataKeyEnum.CARRIERS: {
        return new Carrier(data);
      }
      case ReferenceDataKeyEnum.TERMINALS: {
        return new Terminal(data);
      }
      case ReferenceDataKeyEnum.PRODUCT: {
        return new Product(data);
      }
      default: {
        return new ReferenceDataItem(data);
      }
    }
  }

  public compareForSort(a: any, b: any) {
    if (a.Alias < b.Alias) return -1;
    if (a.Alias > b.Alias) return 1;
    return 0;
  }
}
