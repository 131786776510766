import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { NextPageService } from '@cvx/nextpage';

import { ClrService } from './lcms/services/clr.service';
import { SpinnerService } from './lcms/shared/services/spinner.service';
import { LcmsService } from './lcms/shared/services/lcms.service';
import { AppInsightsService } from './root/services/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'lcms-UI';
  siteName = 'logistics cost management system';
  currentUserProfile!: ICvxClaimsPrincipal;
  isLoggedIn: boolean = false;

  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef!: TemplateRef<any>;

  constructor(
    private authService: CalAngularService,
    private npService: NextPageService,
    private clrService: ClrService,
    private spinner: SpinnerService,
    private lcmsService: LcmsService,
    private vcRef: ViewContainerRef,
    private appInsightsService: AppInsightsService
  ) {
    this.npService.options.sitename = this.siteName;
    this.subscribeToSpinner();
    this.login();
  }

  ngOnInit(): void {
    this.appInsightsService.logPageView(AppComponent.name);
  }

  login() {
    this.authService.userInitiatedSignIn().subscribe({
      next: (user: any) => {
        this.isLoggedIn = user?.isUser;
        if (this.isLoggedIn) {
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
          this.clrService.userName.set(user.name);
          this.clrService.userAADID.set(user.objectId);
          this.appInsightsService.setAuthenticatedUserContext(
            `${user.name}::${user.objectId}`
          );
          this.appInsightsService.logEvent('Login Success', {
            userName: user.name,
          });
        } else {
          console.log('User not yet signed in');
        }
      },
      error: (error) => {
        this.appInsightsService.logEvent('Login Failed', { error });
        console.log(error);
      },
      complete: () => {
        this.appInsightsService.logEvent('Login Completed');
        console.log('Login Completed');
      },
    });
  }

  subscribeToSpinner() {
    this.lcmsService.showSpinner$.subscribe((data) => {
      this.spinner.showSpinner(this.progressSpinnerRef, this.vcRef);
    });

    this.lcmsService.hideSpinner$.subscribe((data) => {
      this.spinner.hideSpinner();
    });
  }
}
