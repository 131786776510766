import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

import { ICustomDateCellEditorParams } from 'src/app/lcms/shared/models/interfaces';
import { LcmsConstants } from 'src/app/lcms/constants';
import { LcmsService } from '../../services/lcms.service';
import { MessagesEnum } from 'src/app/lcms/enums';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-date-picker-cell-editor',
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: './date-picker-cell-editor.component.html',
  styleUrls: ['./date-picker-cell-editor.component.scss'],
})
export class DatePickerCellEditorComponent
  implements AfterViewInit, ICellEditorAngularComp
{
  LcmsConstants = LcmsConstants;
  inputDate: Date | undefined;
  params!: ICellEditorParams & ICustomDateCellEditorParams;

  @ViewChild('dateGridInput', { read: ViewContainerRef })
  public dateGridInput!: any;

  constructor(
    private lcmsService: LcmsService,
    private snackBarService: SnackBarService
  ) {}

  agInit(params: ICellEditorParams & ICustomDateCellEditorParams): void {
    this.params = params;
    if (
      !this.lcmsService.isNullOrUndefined(params.value) &&
      typeof params.value === 'string'
    ) {
      this.inputDate = this.params.value;
    } else {
      this.inputDate = undefined;
    }
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.dateGridInput.element.nativeElement.focus();
    }, 0);
  }

  private isValidDate(input: any): boolean {
    return !isNaN(new Date(input) as any);
  }

  public refresh(_params: any): boolean {
    return true;
  }

  public getValue(): any {
    if (this.inputDate && this.isValidDate(this.inputDate)) {
      return this.lcmsService.getDateISOWithoutTime(this.inputDate);
    } else {
      if (this.params.isMandatory) {
        this.snackBarService.warning({
          message: MessagesEnum.INVALID_DATE,
        });
      }
      return this.params.value;
    }
  }
}
