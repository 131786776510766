import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LicenseManager } from 'ag-grid-enterprise';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

LicenseManager.setLicenseKey(
  'CompanyName=Insight_on_behalf_of_Chevron Technical Center (US, San Ramon, CA, 94583),LicensedGroup=CSCM_ AG_Grid,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-034817,SupportServicesEnd=11_January_2024_[v2]_MTcwNDkzMTIwMDAwMA==2329806a6e0ccb05058157bb0868a449'
);
