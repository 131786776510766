export class GridConstants {
  static ROW_HEIGHT = 35;
  static LOADING_OVERLAY =
    '<span class="ag-overlay-loading-center">Please wait while data is loading.</span>';
  static ROW_SELECTION = 'multiple';
  static MULTI_SORT_KEY = 'ctrl';
  static ROW_GROUP_PANEL_SHOW_WHEN_GROUPING = 'onlyWhenGrouping';
  static ROW_GROUP_PANEL_SHOW_ALWAYS = 'always';
  static PAGINATION_PAGE_SIZE = 50;
  static UNDO_REDO_EDIT_LIMIT = 20;
  static MENU_COL_WIDTH = 44;
  static TOOLTIP_DELAY = 0;
  static TOOLTIP_HIDE_DELAY = 200000;
}

export class LcmsConstants {
  static DEFAULT_CURRENCY = '$';
  static DATE_FORMAT = 'MM/DD/YYYY';
  static LIVE_VERSION = 'Draft Version';
}

export class ValueConstants {
  static readonly MONTH_SHORT_ABBV = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
}
