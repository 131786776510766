export const environment = {
  production: false,
  clientId: '2bc5c284-0f74-48a4-8209-449906c3efaf',
  LCMS_BFF_tokenEndpoint:
    'https://lcms-test.azure.chevron.com/ratedatabffsvc/user_impersonation',
  calRedirectURI: 'https://lcms-test.azure.chevron.com',
  LCMSTruckAPI:
    'https://cscm-lcms-test-ratedata-bff-af-cvx.azurewebsites.net/api',
  appInsights: {
    instrumentationKey: 'fb828856-3bf9-4ae2-a88c-697539d63b8d',
  },
};
