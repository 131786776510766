import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  ApplicationInsights,
  ICustomProperties,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router: router,
          },
        },
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelop: any) => {
      envelop.tags['ai.cloud.role'] = 'LogisticsCostManagementSystem';
    });
  }

  public setAuthenticatedUserContext(userName: string) {
    this.appInsights.setAuthenticatedUserContext(userName);
  }

  public logPageView(name?: string, properties?: { [key: string]: any }) {
    this.appInsights.trackPageView({
      name,
      uri: window.location.href,
      properties,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  /**
   *
   * @param exception @type {Error} Error object(s)
   * @param severityLevel @type {SeverityLevel} Verbose = 0, Information = 1, Warning = 2, Error = 3, Critical = 4
   * @param properties Custom properties
   */
  public logException(
    exception: Error,
    severityLevel?: SeverityLevel,
    properties?: ICustomProperties
  ) {
    this.appInsights.trackException({ exception, severityLevel }, properties);
  }

  public logTrace(message: string, properties?: ICustomProperties) {
    this.appInsights.trackTrace({ message }, properties);
  }

  public flush() {
    this.appInsights.flush();
  }
}
