import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';

import { RoleGuard } from './lcms/shared/guards/role-guard.guard';
import { LCMSAccessTypes } from './lcms/enums';

const routes: Routes = [
  {
    path: 'truck',
    title: 'Logistics Cost Management System',
    loadChildren: () => import('./lcms/lcms-routes').then((r) => r.lcmsRoutes),
    canActivate: [CalGuardService, RoleGuard],
    data: {
      minAccess: LCMSAccessTypes.LCMS_TRUCK_USER,
    },
  },
  {
    path: '401',
    loadComponent: () =>
      import(
        './lcms/shared/components/not-authorized/not-authorized.component'
      ).then((c) => c.NotAuthorizedComponent),
  },
  {
    path: '',
    redirectTo: '/truck/carrier-lane-rates',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/truck/carrier-lane-rates',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
