import {
  HttpClient,
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { MicroServiceType } from '../../enums';
import { DeleteCarrierTerminalMapping } from 'src/app/lcms/shared/models/truck/carrier-terminal-mapping.model';

export const MICRO_SERVICE_TYPE = new HttpContextToken<string>(
  () => MicroServiceType.LCMS_BFF
);

@Injectable({
  providedIn: 'root',
})
export class LcmsHttpService {
  constructor(private http: HttpClient) {}

  public getAllRefData(): Observable<any> {
    let body = {
      mot: 'truck',
    };
    return this.http
      .post(
        `${environment.LCMSTruckAPI}/GetReferenceData`,
        JSON.stringify(body),
        {
          context: new HttpContext().set(
            MICRO_SERVICE_TYPE,
            MicroServiceType.LCMS_BFF
          ),
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          throw err;
        })
      );
  }

  public getAllMappingData(): Observable<any> {
    let body = {
      mot: 'truck',
    };
    return this.http
      .post(
        `${environment.LCMSTruckAPI}/GetMappingData`,
        JSON.stringify(body),
        {
          context: new HttpContext().set(
            MICRO_SERVICE_TYPE,
            MicroServiceType.LCMS_BFF
          ),
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          throw err;
        })
      );
  }

  public updateMappingData(payload: any[]): Observable<any> {
    return this.http
      .post(`${environment.LCMSTruckAPI}/UpsertMappingDataTrigger`, payload, {
        context: new HttpContext().set(
          MICRO_SERVICE_TYPE,
          MicroServiceType.LCMS_BFF
        ),
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          throw err;
        })
      );
  }

  public deleteCarrierTerminalMapping(
    body: DeleteCarrierTerminalMapping
  ): Observable<any> {
    return this.http.delete(
      `${environment.LCMSTruckAPI}/DeleteCarrierTerminalMappingAsync`,
      { body }
    );
  }
}
