import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';

import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { ISnackBarData } from 'src/app/lcms/shared/models/interfaces';

@Component({
  selector: 'app-custom-snack-bar',
  standalone: true,
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss'],
  imports: [NgClass, NgIf],
})
export class CustomSnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData) {}

  close() {
    this.snackBarRef.dismissWithAction();
  }
}
