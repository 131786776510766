import { ValidationType } from 'src/app/lcms/enums';

export class Validations {
  public ValidationType: ValidationType;
  public ScreenType: string;
  public ErrorMessages: string[] = [];
  public WarningMessages: string[] = [];

  constructor(data: any) {
    this.ValidationType = data?.ValidationType;
    this.ScreenType = data?.ScreenType;
    this.ErrorMessages = data?.Errors || [];
    this.WarningMessages = data?.Warnings || [];
  }
}
