import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

import { ICustomNumericCellEditorParams } from 'src/app/lcms/shared/models/interfaces';
import { SnackBarService } from '../../services/snack-bar.service';
import { LcmsService } from '../../services/lcms.service';

@Component({
  selector: 'app-numeric-cell-editor',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './numeric-cell-editor.component.html',
  styleUrls: ['./numeric-cell-editor.component.scss'],
})
export class NumericCellEditorComponent
  implements AfterViewInit, ICellEditorAngularComp
{
  public params!: ICellEditorParams & ICustomNumericCellEditorParams;
  public valueToBind!: number;

  @ViewChild('numericGridInput', { read: ViewContainerRef })
  public numericGridInput: any;

  constructor(
    private snackBarService: SnackBarService,
    private lcmsService: LcmsService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.numericGridInput.element.nativeElement.onkeypress = (event: any) => {
        return this.getValidChar(event, this.params);
      };
      this.numericGridInput.element.nativeElement.focus();
      this.numericGridInput.element.nativeElement.select();
    });
  }

  public agInit(params: any): void {
    if (this.lcmsService.isNullOrUndefined(params.onlyPositiveValue)) {
      params.onlyPositiveValue = true;
    }
    this.params = params;
    this.valueToBind = !this.lcmsService.isNullOrUndefined(params.value)
      ? parseFloat(params.value)
      : params.value;
  }

  private getValidChar(event: any, editableParams: any) {
    let defaultChars = event.charCode >= 48 && event.charCode <= 57;
    let validChar = defaultChars;
    if (
      !this.lcmsService.isNullOrUndefined(editableParams.allowNegative) &&
      editableParams.allowNegative === true
    ) {
      validChar = validChar || event.charCode === 45;
    }
    if (
      !this.lcmsService.isNullOrUndefined(editableParams.allowDecimal) &&
      editableParams.allowDecimal === true
    ) {
      validChar = validChar || event.charCode === 46;
    }
    return validChar;
  }

  private isValidInput(): boolean {
    return (
      this.valueToBind == null ||
      (!isNaN(this.valueToBind) && this.valueToBind > 0)
    );
  }

  public isCancelAfterEnd() {
    if (this.params.validationFn)
      if (this.params.validationFn(this.params, this.valueToBind)) {
        return false;
      } else {
        this.snackBarService.warning({
          message: this.params.validationFailedMsg!,
        });
      }
    return !this.isValidInput();
  }

  public refresh(_params: any): boolean {
    return true;
  }

  public getValue(): any {
    return this.valueToBind;
  }

  public onKeyup(event: any) {
    if (this.params.onInputChange) {
      this.params.onInputChange(this.params, event.target.value);
    }
  }
}
