import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { forkJoin, take } from 'rxjs';

import { ClrService } from 'src/app/lcms/services/clr.service';
import { TruckHomeComponent } from 'src/app/lcms/truck/truck-home/truck-home.component';
import {
  LCMSAccessTypes,
  MessagesEnum,
  MotEnum,
  ReferenceDataKeyEnum,
} from '../enums';
import { ReferenceData } from 'src/app/lcms/shared/models/reference-data';
import { LcmsHttpService } from 'src/app/lcms/shared/services/lcms-http.service';
import { CarrierMapping } from 'src/app/lcms/shared/models/truck/carrier-terminal-mapping.model';
import { TerminalProductMapping } from 'src/app/lcms/shared/models/truck/terminal-product-mapping.model';
import { SnackBarService } from 'src/app/lcms/shared/services/snack-bar.service';
import { LcmsService } from 'src/app/lcms/shared/services/lcms.service';

@Component({
  selector: 'app-lcms-home',
  standalone: true,
  imports: [
    TruckHomeComponent,
    AsyncPipe,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    MatSnackBarModule,
    MatDialogModule,
  ],
  providers: [MatSnackBar, MatDialog],
  templateUrl: './lcms-home.component.html',
  styleUrls: ['./lcms-home.component.scss'],
})
export class LcmsHomeComponent implements OnInit {
  MotEnum = MotEnum;

  constructor(
    private lcmsHttpService: LcmsHttpService,
    private snackBarService: SnackBarService,
    public clrService: ClrService,
    public lcmsService: LcmsService
  ) {}

  ngOnInit(): void {
    if (
      this.lcmsService.userHasReadWriteAccess(LCMSAccessTypes.LCMS_APP_USER)
    ) {
      this.getRefAndMappingData();
    }
    this.clrService.refreshRefMappingDataTrigger$.subscribe((_event: any) =>
      this.getRefAndMappingData()
    );
  }

  private getRefAndMappingData() {
    forkJoin([
      this.lcmsHttpService.getAllRefData(),
      this.lcmsHttpService.getAllMappingData(),
    ])
      .pipe(take(1))
      .subscribe({
        next: ([_refData, _mappingData]) => {
          this.clrService.clrReferenceData = new ReferenceData(
            _refData?.result
          );

          let carrierMappingData = _mappingData?.result?.find(
            (mapping: any) =>
              mapping.EntityType === ReferenceDataKeyEnum.CARRIERS
          );
          this.clrService.allCarrierMappingData =
            carrierMappingData?.Items?.map(
              (m: any) =>
                new CarrierMapping(m, this.clrService.clrReferenceData)
            );
          let terminalMappingData = _mappingData?.result?.find(
            (mapping: any) =>
              mapping.EntityType === ReferenceDataKeyEnum.TERMINALS
          );
          this.clrService.allTerminalMappingData =
            terminalMappingData?.Items?.map(
              (m: any) => new TerminalProductMapping(m, this.clrService)
            );

          this.clrService.isClrRefDataFetched = true;
          this.clrService.clrRefDataSubject$.next(true);
        },
        error: (error: any) => {
          this.snackBarService.error({
            message:
              MessagesEnum.SOMETHING_WENT_WRONG +
              ' :: GetRefData/GetMappingData',
          });
          console.log(error);
        },
      });
  }
}
