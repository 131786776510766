<mat-form-field
  subscriptSizing="dynamic"
  class="custom-editor-field ag-cell-dropdown-field">
  <mat-select
    [(ngModel)]="selectedValue"
    (selectionChange)="onSelectionChange($event)"
    placeholder="-Select-"
    #singleSelectGridInput
    panelClass="ag-custom-component-popup ag-cell-dropdown-select">
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="search"
        [formControl]="selectFilterControl"
        [preventHomeEndKeyPropagation]="true">
        <em
          ngxMatSelectSearchClear
          class="fa fa-times"
          style="font-size: 70%; position: relative; top: 1px"></em>
        <span ngxMatSelectNoEntriesFound> No data found </span>
      </ngx-mat-select-search>
    </mat-option>

    <mat-option
      *ngFor="let item of filteredItemList | async"
      [value]="item[bindValue]">
      {{ item[bindLabel] }}
    </mat-option>
  </mat-select>
</mat-form-field>
