import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IFloatingFilterAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox-floating-filter',
  standalone: true,
  imports: [NgFor, FormsModule],
  templateUrl: './checkbox-floating-filter.component.html',
  styleUrls: ['./checkbox-floating-filter.component.scss'],
})
export class CheckboxFloatingFilterComponent
  implements IFloatingFilterAngularComp
{
  private params: any;
  public currentValue: null | string = null;
  public modelCollection = [
    {
      id: 'true',
      value: 'Yes',
    },
    {
      id: 'false',
      value: 'No',
    },
  ];

  agInit(params: any): void {
    this.params = params;
  }

  onParentModelChanged(parentModel: any) {
    if (!parentModel) {
      this.currentValue = null;
    } else {
      if (
        (parentModel.filter === 'true' || parentModel.filter === '1') &&
        parentModel.type === 'equals'
      ) {
        this.currentValue = 'true';
      } else {
        this.currentValue = 'false';
      }
    }
  }

  onSelectionChange(selection: any) {
    if (selection.target.value === 'true') {
      this.params.parentFilterInstance((instance: any) =>
        instance.onFloatingFilterChanged('equals', true)
      );
    } else if (selection.target.value === 'false') {
      this.params.parentFilterInstance((instance: any) =>
        instance.onFloatingFilterChanged('notEqual', true)
      );
    } else {
      this.params.parentFilterInstance((instance: any) =>
        instance.onFloatingFilterChanged(null, null)
      );
    }
  }
}
